body,
html {
  height: 100%;
  width: 100%;
  padding: 0 0 0 0;
  margin: 0 0 0 0;
}

/* The !imporant is important to overide Bootstrap*/
@media only screen and (orientation: portrait) {
  #card {
    padding: 3% 15% 0% 15% !important;
  }
  h1.largeTitle {
    font-size: 400% !important;
  }
  p.largeTitle {
    font-size: 100% !important;
  }
  #largeTitle {
    top: 30% !important;
  }
}
h3{
  font-size: 20px;
  font-weight: 400;
}
h2{
  text-align: center;
}

p {
  text-indent: 50px;
}

.bottom-paragraph {
  margin: 0% 0% 10% 0%;
}

.bg-dark {
  background: transparent !important;
  display: flex;
  justify-content: space-around;
}

#card {
  padding: 3% 30% 0% 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: black;
}

/* Text for the main navigation bar */
#mainNavText {
  color: white;
  font-size: larger;
  text-decoration: underline;
}

#mainNavText:hover {
  color: lightgray;
  text-decoration: none;
}

/* Name in the top right hand corner */
#edwin {
  color: white;
  font-size: larger;
}

.hero {
  height: 100vh;
  display: flex;
  position: relative;
  background-image: url("./images/seattle.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/* Large title in the middle of the hero */
#largeTitle {
  height: 100%;
  color: white;
  position: absolute;
  top: 40%;
}

h1.largeTitle {
  font-size: 500%;
}

p.largeTitle {
  font-size: 200%;
}
